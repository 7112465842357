import React from 'react'
import { Link } from 'react-router-dom'
import TopNav from './TopNav'

export default function Menu() {
  return (
    <div>
        {/* Navbar Start */}
        <div className="container-fluid fixed-top px-0 wow fadeIn" data-wow-delay="0.1s">
            {/* Top Navigation */}
            <TopNav />

            <nav className="navbar navbar-expand-lg navbar-light py-lg-0 px-lg-5 wow fadeIn" data-wow-delay="0.1s">
                <a href="/" className="navbar-brand ms-4 ms-lg-0">
                    <img src='assets/img/logo.png' alt="mdfa" />
                </a>
                <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto p-4 p-lg-0">
                        <Link to="/" className="nav-item nav-link active">Home</Link>
                        <Link to="/about" className="nav-item nav-link">About Us</Link>
                        <Link to="/farmers" className="nav-item nav-link">Farmers</Link>
                        <div className="nav-item dropdown">
                            <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Services</Link>
                            <div className="dropdown-menu m-0">
                                <Link to="/blog" className="dropdown-item">Blog</Link>
                                <Link to="/projects" className="dropdown-item">Projects</Link>
                                <Link to="/testimonial" className="dropdown-item">Testimonial</Link>
                                {/* <Link to="/404" className="dropdown-item">404 Page</Link> */}
                            </div>
                        </div>
                        <Link to="/contact" className="nav-item nav-link">Contact Us</Link>
                    </div>
                    <div className="d-none d-lg-flex ms-2">
                        <a href="https://flutterwave.com/donate/te9nhnou24vy" target="_blank" rel="noreferrer">
                            <div className="button">
                                <p className="btnText">Donate</p>
                                <div className="btnTwo">
                                    <p className="btnText2"><i className='fa fa-heart'></i></p>
                                </div>
                            </div>

                        </a>

                        {/* <Link className="btn-sm-square bg-white rounded-circle ms-3" to="#">
                            <small className="fa fa-user text-body"></small>
                        </Link>
                        <Link className="btn-sm-square bg-white rounded-circle ms-3" to="#">
                            <small className="fa fa-shopping-bag text-body"></small>
                        </Link> */}
                    </div>
                </div>
            </nav>
        </div>
        {/* Navbar End */}
    </div>
  )
}
