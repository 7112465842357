import React from 'react';
import { Link } from 'react-router-dom';

export default function SampleBlog() {
  return (
    <React.Fragment>
        <div className="row g-4">
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <img className="img-fluid" style={{width: '100%', height: '170px'}} src="assets/img/blog-4.jpg" alt="" />
                <div className="bg-light p-4">
                    <Link className="d-block h5 lh-base mb-4" to="">
                        Masaka District Farmers Association staff 
                        , Board and Vi Agroforestry Representative Ms 
                        Teddy Nakayenga participating in tree planting. 
                    </Link>
                    <p>
                        MDFA joins the rest of 
                        the world to celebrate the International Day of Forests 
                        (IDF) by planting trees in Busense Village, Kabonera Sub 
                        County in Masaka District on 22 March 2021 under the 
                        theme “Forest Restoration: A Path To Recovery and Wellbeing”
                    </p>
                    <div className="text-muted border-top pt-4">
                        <small className="me-3"><i className="fa fa-user text-primary me-2"></i>Admin</small>
                        <small className="me-3"><i className="fa fa-calendar text-primary me-2"></i>01 Jan, 2022</small>
                    </div>
                </div>
            </div>

            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <img className="img-fluid" style={{width: '100%', height: '170px'}} src="assets/img/blog-5.jpg" alt="" />
                <div className="bg-light p-4">
                    <Link className="d-block h5 lh-base mb-4" to="">
                        Field Officer Buwunga Sub County Ms.Nantongo Oliver 
                        sensitizing pupils of Kasaka Primary school on 
                        value of trees and tree planting.
                    </Link>
                    <p>
                        In the struggle against climate change, Masaka District 
                        Farmers Association together with Caritas MADDO 
                        sensitize residents of Kajuna Village, Buwunga Sub 
                        County about climate change mitigation measures by 
                        holding a tree planting campaign and exhibiting wood 
                        saving stoves at Kajuna Church on 27th May 2021.
                    </p>
                    <div className="text-muted border-top pt-4">
                        <small className="me-3"><i className="fa fa-user text-primary me-2"></i>Admin</small>
                        <small className="me-3"><i className="fa fa-calendar text-primary me-2"></i>27 May, 2021</small>
                    </div>
                </div>
            </div>

            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                <img className="img-fluid" style={{width: '100%', height: '170px'}} src="assets/img/blog-6.jpg" alt="" />
                <div className="bg-light p-4">
                    <Link className="d-block h5 lh-base mb-4" to="">
                        Masaka City Mayor Representative Ms.Nabukenya Jane 
                        Francis launching MDFA food processing factory
                    </Link>
                    <p>
                        Farmers in Masaka excited about launching of the MDFA factory 
                        for cassava and maize flour processing during celebration of 
                        World Food Day at Masaka District Farmers Association factory 
                        in Kirumba Industrial Area on 14th October 2021 under the theme 
                        “Our Actions are our future, better production, better nutrition, 
                        better environment and better life"
                    </p>
                    <div className="text-muted border-top pt-4">
                        <small className="me-3"><i className="fa fa-user text-primary me-2"></i>Admin</small>
                        <small className="me-3"><i className="fa fa-calendar text-primary me-2"></i>14 Oct, 2021</small>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
  )
}
