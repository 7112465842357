import React from 'react'

export default function FarmersTestimonials() {
  return (
    <div className="container-fluid bg-light bg-icon py-6">
        <div className="container">
            <div className="section-header text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: '500px', visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp'}}>
                <h1 className="display-5 mb-3">Testimonial Review</h1>
                <p>
                    Our Farmers, cooperatives and individuals (both small and large scale) narrates their Journeys to successful farming
                </p>
            </div>
            <div className="owl-carousel testimonial-carousel wow fadeInUp" data-wow-delay="0.1s">
                <div className="testimonial-item position-relative bg-white p-5 mt-4">
                    <i className="fa fa-quote-left fa-3x text-primary position-absolute top-0 start-0 mt-n4 ms-5"></i>
                    <h5> Masaka DFA improves community livelihoods cassava value addition</h5>
                    <p className="mb-4">
                        Nalubowa Sarah of Kayugi Group C in Mukungwe Sub County, 
                        Masaka District is one of the farmers who have embraced 
                        cassava value addition not only as a way of increasing 
                        food availability but also a business generating income 
                        as she reports “After receiving the planting materials, 
                        tarpaulins and the training on how to use the slicing machine, 
                        I picked interest in how to add value on cassava tubers 
                        through processing cassava chips”. “We extended our Cassava 
                        garden to 1.5 acres and in the first season we were able to 
                        produce 2,280Kgs of dried chips which we sold at an average 
                        price of Ushs 500 per Kg” she adds. “When we extended our 
                        Cassava garden to 2 acres, our latest harvest increased and 
                        we were able to chip and sell 2,850 Kgsof cassava chipsat an 
                        average price of Ushs. 510” Narrates MsNalubowa.
                    </p>
                    <h5>Achievement</h5>
                    <p className="mb-4">
                        “We have been able to renovate our old house from the proceeds 
                        got from cassava, we have also established a retail shop using 
                        the money we get from Cassava, my family is happy and we 
                        thank MDFA for the support”, said MsNalubowa. She also adds 
                        that she now mills cassava flour from the chips and sells in 
                        her shop at UGX. 1,000 per kilogram.
                    </p>
                    <div className="d-flex align-items-center">
                        <img className="flex-shrink-0 rounded-circle" src="assets/img/testimonial-5.jpg" alt="" />
                        <div className="ms-3">
                            <h5 className="mb-1">Nalubowa Sarah and her family processing and packing cassava chips</h5>
                            <span>Kayugi Group C in Mukungwe Sub County, Masaka District</span>
                        </div>
                    </div>
                </div>

                <div className="testimonial-item position-relative bg-white p-5 mt-4">
                    <i className="fa fa-quote-left fa-3x text-primary position-absolute top-0 start-0 mt-n4 ms-5"></i>
                    <h5>Water harvesting technologies improving livelihoods</h5>
                    <p className="mb-4">
                        By supporting farmers in water harvesting, a number of families 
                        in Masaka District have received underground water tanks of 
                        different capacities such as 10,000 liters, 15,000 liters. 
                        These structures have reduced on the time spent and distances 
                        moved when fetching water, irrigation of plantations especially 
                        in the dry season, some families with livestock animals report 
                        increased saving on water bills as well as improved social 
                        relationship with neighbours who fetch water especially in 
                        the dry season. Examples include Nankya Christine in Mukungwe 
                        Sub County who reported a saving of half the money she used 
                        to spend on water bills ever since MDFA supported her to 
                        construct a tank on her farm. Other farmers with tanks 
                        includeAkankwasa Elizabeth in Buwunga Sub County.
                    </p>
                    <div className="d-flex align-items-center">
                        <img className="flex-shrink-0 rounded-circle" src="assets/img/testimonial-9.jpg" alt="" />
                        <div className="ms-3">
                            <h5 className="mb-1">Akankwasa Elizabeth </h5>
                            <span>Underground tank for Akankwasa Elizabeth</span>
                        </div>
                    </div>
                </div>

                <div className="testimonial-item position-relative bg-white p-5 mt-4">
                    <i className="fa fa-quote-left fa-3x text-primary position-absolute top-0 start-0 mt-n4 ms-5"></i>
                    <h5>Agroforestry utilizing waste lands in Masaka</h5>
                    <p className="mb-4">
                        MDFA promoting climate change mitigation, a number of 
                        trees have been planted on boundaries, as woodlots, 
                        shade trees and for firewood. Through this, various 
                        pieces of wasted lands have been restored back to productivity. 
                        An example of such restored lands is at MrsBazanye Betty 
                        of Lwanunda Farmer Group in Buwunga Sub County where 
                        1.5 acres of land was restored by planting a grivelia 
                        woodlot for firewood and timber provision.
                    </p>
                    <div className="d-flex align-items-center">
                        <img className="flex-shrink-0 rounded-circle" src="assets/img/testimonial-7.jpg" alt="" />
                        <div className="ms-3">
                            <h5 className="mb-1">Mr.Bikanyulo</h5>
                            <span>1 acre woodlot established at Mr.Bikanyulo in Njumaga Village in Mukungwe Sub County</span>
                        </div>
                    </div>
                </div>

                <div className="testimonial-item position-relative bg-white p-5 mt-4">
                    <i className="fa fa-quote-left fa-3x text-primary position-absolute top-0 start-0 mt-n4 ms-5"></i>
                    <h5>
                        Joint decision making and Household Road map a key to livelihood empowerment
                    </h5>
                    <p className="mb-4">
                        With empowerment of households in joint decision making, 
                        many families plan and work together. This has improved 
                        production and productivity in many families especially 
                        those that establish and implement vision journeys. 
                        An example of such a household is of Namuli Teddy of 
                        Twekolerewomens group in Lwabikere Village Mukungwe 
                        Sub County. She drew a vision journey of buying land 
                        which she achieved.
                    </p>
                    <div className="d-flex align-items-center">
                        <img className="flex-shrink-0 rounded-circle" src="assets/img/testimonial-8.jpg" alt="" />
                        <div className="ms-3">
                            <h5 className="mb-1">Ms.Namuli</h5>
                            <span>
                                Ms.Namuli posing in her newly bought land and building 
                                materials (bricks) in Lwabikere Village in Mukungwe 
                                Sub County
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
