import React from 'react';
import { Link } from 'react-router-dom';
import AboutUsPage from '../components/AboutPage';
import Farmers from '../components/Farmers';
import FarmersTestimonials from '../components/FarmersTestimonials';
import SampleBlog from '../components/SampleBlog';

import { useSinglePrismicDocument } from '@prismicio/react';


export default function Home() {
    const [document] = useSinglePrismicDocument("home")

    // console.log(document)

    return (
        <React.Fragment>
            {
                document && (
                    <div>
                            {/* Carousel Start */}
                            <div className="container-fluid p-0 mb-5 wow fadeIn" data-wow-delay="0.1s">
                        
                                <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
                                {/* {console.log()} */}
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <img className="w-100" src={document.data.slider_image_one.url} alt="mdfa" />
                                            <div className="carousel-caption">
                                                <div className="container">
                                                    <div className="row justify-content-start">
                                                        <div className="col-lg-7">
                                                            <h1 className="display-2 mb-5 animated slideInDown">
                                                                {document.data.slider_text_one[0].text}
                                                            </h1>
                                                            <Link to="/farmers" className="btn btn-primary rounded-pill py-sm-3 px-sm-5">
                                                                {document.data.btn_text[0].text}
                                                            </Link>
                                                            <a href="https://flutterwave.com/donate/te9nhnou24vy" 
                                                                className="btn btn-secondary rounded-pill py-sm-3 px-sm-5 ms-3"
                                                                target="_blank" rel="noreferrer">
                                                                {document.data.btn_text1[0].text}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <img className="w-100" src={document.data.slider_image_two.url} alt="mdfa" />
                                            <div className="carousel-caption">
                                                <div className="container">
                                                    <div className="row justify-content-start">
                                                        <div className="col-lg-7">
                                                            <h1 className="display-2 mb-5 animated slideInDown">{document.data.slider_text_two[0].text}</h1>
                                                            <Link to="/farmers" className="btn btn-primary rounded-pill py-sm-3 px-sm-5">
                                                                {document.data.btn_text[0].text}
                                                            </Link>
                                                            <a href="https://flutterwave.com/donate/te9nhnou24vy" 
                                                                className="btn btn-secondary rounded-pill py-sm-3 px-sm-5 ms-3"
                                                                target="_blank" rel="noreferrer">
                                                                {document.data.btn_text1[0].text}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                                        data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
                                        data-bs-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>
                    </div>
                    {/* Carousel End */}

                    <AboutUsPage /> 
                    
                    {/* Product Start */}

                    <Farmers />

                    {/* Testimonial Start */}
                    <FarmersTestimonials />
                    {/* Testimonial End */}

                    {/* Blog Start */}
                    <div className="container-xxl py-5">
                        <div className="container">
                            <div className="section-header text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: '500px'}}>
                                <h1 className="display-5 mb-3">{document.data.blog_text[0].text}</h1>
                                <p>{document.data.blog_text1[0].text}</p>
                            </div>
                            {/* Blog start */}
                            <SampleBlog />
                        </div>
                    </div>
                    {/* Blog End */}
                </div>
            )}  
        </React.Fragment>
    )
}
