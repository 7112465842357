import React, { Component } from 'react';
import { 
  BrowserRouter as Router, 
  Link, 
  Route, 
  Routes,
} from "react-router-dom";

import Menu from './components/Menu';
import Footer from './components/Footer';
// import "./App.css";
// pages
import Home from './pages/Home';
import About from './pages/About';
import Farmers from './pages/Farmers';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import Projects from './pages/Projects';
import Testimonial from './pages/Testimonial';
import Default from './pages/404';


export default class App extends Component {
  // state = {
  //   isLoading: true
  // }

  // componentDidMount() {
  //   setTimeout(() => {
  //     this.setState(
  //       {
  //         isLoading: false
  //       }
  //     )
  //   }, 500)
  // }

  // componentWillUnmount() {
  //   clearTimeout(() => this.state.isLoading)
  // }

  render () {
    // this.state.isLoading ? 
      // <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
      //     Loading.. <div className="spinner-border text-primary" role="status"></div>
      // </div>
    return (
      <React.Fragment>
        <Router>
          {/* Navbar Menu */}
          <Menu />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path='/farmers' element={<Farmers />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/blog' element={<Blog />} />
            <Route path='/Projects' element={<Projects />} />
            <Route path='/testimonial' element={<Testimonial />} />
            <Route path="*" element={<Default />} />
          </Routes>

          {/* Footer */}
          <Footer />

          <div>
            {/* Back to Top */}
            <Link to="#" className="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"><i className="bi bi-arrow-up"></i></Link>
          </div>
        </Router>

      </React.Fragment>
    );
  }
}
