import React from 'react';
import { Link } from 'react-router-dom';

export default function Farmers() {
  return (
    <div className="container-xxl py-5">
        <div className="container">
            <div className="row g-0 gx-5 align-items-end">
                <div className="col-lg-6">
                    <div className="section-header text-start mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: '500px'}}>
                        <h1 className="display-5 mb-3">Our Farmers</h1>
                        <p>
                            
                        </p>
                    </div>
                </div>
                <div className="col-lg-10 text-start text-lg-end wow slideInRight" data-wow-delay="0.1s">
                    <ul className="nav nav-pills d-inline-flex justify-content-end mb-5">
                        <li className="nav-item me-2">
                            <Link className="btn btn-outline-primary border-2 active" data-bs-toggle="pill" to="#tab-1">Fruits and vegetables </Link>
                        </li>
                        <li className="nav-item me-2">
                            <Link className="btn btn-outline-primary border-2" data-bs-toggle="pill" to="#tab-2">Energy conservation and Bio char</Link>
                        </li>
                        <li className="nav-item me-0">
                            <Link className="btn btn-outline-primary border-2" data-bs-toggle="pill" to="#tab-3">Cassava value chain and Gender</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="tab-content">
                <div id="tab-1" className="tab-pane fade show p-0 active">
                    <div className="row g-4">
                        <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="product-item">
                                <div className="position-relative bg-light overflow-hidden">
                                    <img className="img-fluid w-100" src="assets/img/Picture1.jpg" alt="" />
                                    <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">1</div>
                                </div>
                                <div className="text-center p-4">
                                    <Link className="d-block h5 mb-2" to="#">
                                        Kibindi Farmer Group
                                    </Link>
                                    <span className="text-primary me-1">
                                        Nansamba Lukia of Kibindi Farmer Group in Buwunga Sub County 
                                        harvesting vegetables from her nutrition garden
                                    </span>
                                    {/* <span className="text-body text-decoration-line-through">$29.00</span> */}
                                </div>
                                <div className="d-flex border-top">
                                    <small className="w-50 text-center border-end py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-eye text-primary me-2"></i>View detail</Link>
                                    </small>
                                    {/* <small className="w-50 text-center py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-shopping-bag text-primary me-2"></i></Link>
                                    </small> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="product-item">
                                <div className="position-relative bg-light overflow-hidden">
                                    <img className="img-fluid w-100" src="assets/img/Picture2.jpg" alt="mdfa" />
                                    <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">2</div>
                                </div>
                                <div className="text-center p-4">
                                    <Link className="d-block h5 mb-2" to="#">Mukungwe Sub County</Link>
                                    <span className="text-primary me-1">Nutrition Gardens at Mr.Ssematimba Francis in Mukungwe Sub County</span>
                                    {/* <span className="text-body text-decoration-line-through">$29.00</span> */}
                                </div>
                                <div className="d-flex border-top">
                                    <small className="w-50 text-center border-end py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-eye text-primary me-2"></i>View detail</Link>
                                    </small>
                                    {/* <small className="w-50 text-center py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-shopping-bag text-primary me-2"></i>Add to cart</Link>
                                    </small> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="product-item">
                                <div className="position-relative bg-light overflow-hidden">
                                    <img className="img-fluid w-100" src="assets/img/Picture3.jpg" alt="mdfa" />
                                    <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">3</div>
                                </div>
                                <div className="text-center p-4">
                                    <Link className="d-block h5 mb-2" to="#">Ms.Nagawa Milly</Link>
                                    <span className="text-primary me-1">
                                        Field visit of established nutrition gardens at Ms.Nagawa Milly in Gulama Village, Buwunga Sub County</span>
                                    {/* <span className="text-body text-decoration-line-through">$29.00</span> */}
                                </div>
                                <div className="d-flex border-top">
                                    <small className="w-50 text-center border-end py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-eye text-primary me-2"></i>View detail</Link>
                                    </small>
                                    {/* <small className="w-50 text-center py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-shopping-bag text-primary me-2"></i>Add to cart</Link>
                                    </small> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                            <div className="product-item">
                                <div className="position-relative bg-light overflow-hidden">
                                    <img className="img-fluid w-100" src="assets/img/Picture5.jpg" alt="mdfa" />
                                    <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">4</div>
                                </div>
                                <div className="text-center p-4">
                                    <Link className="d-block h5 mb-2" to="#">Buwunga Sub County Farmers</Link>
                                    <span className="text-primary me-1">
                                        Demonstration on liquid manure application in banana plantation for farmers in Buwunga Sub County.
                                    </span>
                                    {/* <span className="text-body text-decoration-line-through">$29.00</span> */}
                                </div>
                                <div className="d-flex border-top">
                                    <small className="w-50 text-center border-end py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-eye text-primary me-2"></i>View detail</Link>
                                    </small>
                                    {/* <small className="w-50 text-center py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-shopping-bag text-primary me-2"></i>Add to cart</Link>
                                    </small> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="product-item">
                                <div className="position-relative bg-light overflow-hidden">
                                    <img className="img-fluid w-100" src="assets/img/Picture4.jpg" alt="mdfa" />
                                    <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">5</div>
                                </div>
                                <div className="text-center p-4">
                                    <Link className="d-block h5 mb-2" to="#">Kasala Central Farmers Group</Link>
                                    <span className="text-primary me-1">
                                        Nabukenya Janat of Kasala Central Farmers Group making soil 
                                        and water conservation structures in her banana plantation 
                                        in Mukungwe Sub County
                                    </span>
                                    {/* <span className="text-body text-decoration-line-through">$29.00</span> */}
                                </div>
                                <div className="d-flex border-top">
                                    <small className="w-50 text-center border-end py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-eye text-primary me-2"></i>View detail</Link>
                                    </small>
                                    {/* <small className="w-50 text-center py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-shopping-bag text-primary me-2"></i>Add to cart</Link>
                                    </small> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="product-item">
                                <div className="position-relative bg-light overflow-hidden">
                                    <img className="img-fluid w-100" src="assets/img/Picture6.jpg" alt="mdfa" />
                                    <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">6</div>
                                </div>
                                <div className="text-center p-4">
                                    <Link className="d-block h5 mb-2" to="#">Buwunga Sub County</Link>
                                    <span className="text-primary me-1">
                                        Nakiyaga Kwagalana Womens Group during saving session in their VSLA in Buwunga Sub County
                                    </span>
                                    {/* <span className="text-body text-decoration-line-through">$29.00</span> */}
                                </div>
                                <div className="d-flex border-top">
                                    <small className="w-50 text-center border-end py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-eye text-primary me-2"></i>View detail</Link>
                                    </small>
                                    {/* <small className="w-50 text-center py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-shopping-bag text-primary me-2"></i>Add to cart</Link>
                                    </small> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="product-item">
                                <div className="position-relative bg-light overflow-hidden">
                                    <img className="img-fluid w-100" src="assets/img/Picture7.jpg" alt="mdfa" />
                                    <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">7</div>
                                </div>
                                <div className="text-center p-4">
                                    <Link className="d-block h5 mb-2" to="#">Wood Saving Stove</Link>
                                    <span className="text-primary me-1">
                                        Some of the Wood saving stove constructed
                                        at Mrs. Mutyaba Teddy minimizing firewood usage 
                                    </span>
                                    {/* <span className="text-body text-decoration-line-through">$29.00</span> */}
                                </div>
                                <div className="d-flex border-top">
                                    <small className="w-50 text-center border-end py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-eye text-primary me-2"></i>View detail</Link>
                                    </small>
                                    {/* <small className="w-50 text-center py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-shopping-bag text-primary me-2"></i>Add to cart</Link>
                                    </small> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                            <div className="product-item">
                                <div className="position-relative bg-light overflow-hidden">
                                    <img className="img-fluid w-100" src="assets/img/Picture8.jpg" alt="mdfa" />
                                    <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">8</div>
                                </div>
                                <div className="text-center p-4">
                                    <Link className="d-block h5 mb-2" to="#">Passion Fruit Farmers</Link>
                                    <span className="text-primary me-1">
                                        Namuwonge Amina a passion fruit farmer harvesting passion fruit in Buwunga Sub 
                                    </span>
                                    {/* <span className="text-body text-decoration-line-through">$29.00</span> */}
                                </div>
                                <div className="d-flex border-top">
                                    <small className="w-50 text-center border-end py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-eye text-primary me-2"></i>View detail</Link>
                                    </small>
                                    {/* <small className="w-50 text-center py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-shopping-bag text-primary me-2"></i>Add to cart</Link>
                                    </small> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                            <Link className="btn btn-primary rounded-pill py-3 px-5" to="#">Browse More Products</Link>
                        </div>
                    </div>
                </div>
                <div id="tab-2" className="tab-pane fade show p-0">
                    <div className="row g-4">
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="product-item">
                            <div className="position-relative bg-light overflow-hidden">
                                    <img className="img-fluid w-100" src="assets/img/Picture8.jpg" alt="mdfa" />
                                    <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">1</div>
                                </div>
                                <div className="text-center p-4">
                                    <Link className="d-block h5 mb-2" to="#">Passion Fruit Farmers</Link>
                                    <span className="text-primary me-1">
                                        Namuwonge Amina a passion fruit farmer harvesting passion fruit in Buwunga Sub 
                                    </span>
                                    {/* <span className="text-body text-decoration-line-through">$29.00</span> */}
                                </div>
                                <div className="d-flex border-top">
                                    <small className="w-50 text-center border-end py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-eye text-primary me-2"></i>View detail</Link>
                                    </small>
                                    {/* <small className="w-50 text-center py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-shopping-bag text-primary me-2"></i>Add to cart</Link>
                                    </small> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="product-item">
                                <div className="position-relative bg-light overflow-hidden">
                                    <img className="img-fluid w-100" src="assets/img/Picture19.jpg" alt="mdfa" />
                                    <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">2</div>
                                </div>
                                <div className="text-center p-4">
                                    <Link className="d-block h5 mb-2" to="#">Ms.Nabbuma Gorreti</Link>
                                    <span className="text-primary me-1">
                                        Ms.Nabbuma Gorreti and her grand children drawing their vision journey in Kayugi Village, 
                                        Mukungwe Sub County
                                    </span>
                                    {/* <span className="text-body text-decoration-line-through">$29.00</span> */}
                                </div>
                                <div className="d-flex border-top">
                                    <small className="w-50 text-center border-end py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-eye text-primary me-2"></i>View detail</Link>
                                    </small>
                                    {/* <small className="w-50 text-center py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-shopping-bag text-primary me-2"></i>Add to cart</Link>
                                    </small> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="product-item">
                                <div className="position-relative bg-light overflow-hidden">
                                    <img className="img-fluid w-100" src="assets/img/Picture20.jpg" alt="mdfa" />
                                    <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">3</div>
                                </div>
                                <div className="text-center p-4">
                                    <Link className="d-block h5 mb-2" to="#">Cassava Value Addition</Link>
                                    <span className="text-primary me-1">
                                        Demonstration of cassava value addition using a chipping machine by Ms.Namugenyi Norah of Kyamuyimbwa Farmer Group in Kabonera Sub County
                                    </span>
                                    {/* <span className="text-body text-decoration-line-through">$29.00</span> */}
                                </div>
                                <div className="d-flex border-top">
                                    <small className="w-50 text-center border-end py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-eye text-primary me-2"></i>View detail</Link>
                                    </small>
                                    {/* <small className="w-50 text-center py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-shopping-bag text-primary me-2"></i>Add to cart</Link>
                                    </small> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="product-item">
                                <div className="position-relative bg-light overflow-hidden">
                                    <img className="img-fluid w-100" src="assets/img/Picture21.jpg" alt="mdfa" />
                                    <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">4</div>
                                </div>
                                <div className="text-center p-4">
                                    <Link className="d-block h5 mb-2" to="#">Biochar Sieving</Link>
                                    <span className="text-primary me-1">
                                        Biochar sieving by Twekolere Womens group
                                    </span>
                                    {/* <span className="text-body text-decoration-line-through">$29.00</span> */}
                                </div>
                                <div className="d-flex border-top">
                                    <small className="w-50 text-center border-end py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-eye text-primary me-2"></i>View detail</Link>
                                    </small>
                                    {/* <small className="w-50 text-center py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-shopping-bag text-primary me-2"></i>Add to cart</Link>
                                    </small> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="product-item">
                                <div className="position-relative bg-light overflow-hidden">
                                    <img className="img-fluid w-100" src="assets/img/Picture22.jpg" alt="mdfa" />
                                    <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">5</div>
                                </div>
                                <div className="text-center p-4">
                                    <Link className="d-block h5 mb-2" to="#">Cassava Planting Materials</Link>
                                    <span className="text-primary me-1">
                                        Distribution of cassava planting materials to farmers in Mukungwe and Kabonera Sub Counties
                                    </span>
                                    {/* <span className="text-body text-decoration-line-through">$29.00</span> */}
                                </div>
                                <div className="d-flex border-top">
                                    <small className="w-50 text-center border-end py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-eye text-primary me-2"></i>View detail</Link>
                                    </small>
                                    {/* <small className="w-50 text-center py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-shopping-bag text-primary me-2"></i>Add to cart</Link>
                                    </small> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="product-item">
                                <div className="position-relative bg-light overflow-hidden">
                                    <img className="img-fluid w-100" src="assets/img/product-6.jpg" alt="mdfa" />
                                    <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">6</div>
                                </div>
                                <div className="text-center p-4">
                                    <Link className="d-block h5 mb-2" to="#">Fresh Tomato</Link>
                                    <span className="text-primary me-1">$19.00</span>
                                    <span className="text-body text-decoration-line-through">$29.00</span>
                                </div>
                                <div className="d-flex border-top">
                                    <small className="w-50 text-center border-end py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-eye text-primary me-2"></i>View detail</Link>
                                    </small>
                                    <small className="w-50 text-center py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-shopping-bag text-primary me-2"></i>Add to cart</Link>
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="product-item">
                                <div className="position-relative bg-light overflow-hidden">
                                    <img className="img-fluid w-100" src="assets/img/product-7.jpg" alt="mdfa" />
                                    <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">7</div>
                                </div>
                                <div className="text-center p-4">
                                    <Link className="d-block h5 mb-2" to="#">Fresh Tomato</Link>
                                    <span className="text-primary me-1">$19.00</span>
                                    <span className="text-body text-decoration-line-through">$29.00</span>
                                </div>
                                <div className="d-flex border-top">
                                    <small className="w-50 text-center border-end py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-eye text-primary me-2"></i>View detail</Link>
                                    </small>
                                    <small className="w-50 text-center py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-shopping-bag text-primary me-2"></i>Add to cart</Link>
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="product-item">
                                <div className="position-relative bg-light overflow-hidden">
                                    <img className="img-fluid w-100" src="assets/img/product-8.jpg" alt="mdfa" />
                                    <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">8</div>
                                </div>
                                <div className="text-center p-4">
                                    <Link className="d-block h5 mb-2" to="#">Banana Plantation &amp; Harvesting</Link>
                                    <span className="text-primary me-1">
                                        Nabukenya Janat of Kasala Central Farmers Group making soil and water conservation structures in her banana plantation in Mukungwe Sub County
                                    </span>
                                    {/* <span className="text-body text-decoration-line-through">$29.00</span> */}
                                </div>
                                <div className="d-flex border-top">
                                    <small className="w-50 text-center border-end py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-eye text-primary me-2"></i>View detail</Link>
                                    </small>
                                    {/* <small className="w-50 text-center py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-shopping-bag text-primary me-2"></i>Add to cart</Link>
                                    </small> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-center">
                            <Link className="btn btn-primary rounded-pill py-3 px-5" to="#">Browse More Products</Link>
                        </div>
                    </div>
                </div>
                <div id="tab-3" className="tab-pane fade show p-0">
                    <div className="row g-4">
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="product-item">
                                <div className="position-relative bg-light overflow-hidden">
                                    <img className="img-fluid w-100" src="assets/img/Picture9.jpg" alt="mdfa" />
                                    <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">1</div>
                                </div>
                                <div className="text-center p-4">
                                    <Link className="d-block h5 mb-2" to="#">Tree Planting Campaign</Link>
                                    <span className="text-primary me-1">
                                        Ordinard Yona Byakatonda and Mr.Segawa Hassan planting a tree at Kajuna Church during tree planting campaign in Buwunga Sub County 
                                    </span>
                                    {/* <span className="text-body text-decoration-line-through">$29.00</span> */}
                                </div>
                                <div className="d-flex border-top">
                                    <small className="w-50 text-center border-end py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-eye text-primary me-2"></i>View detail</Link>
                                    </small>
                                    {/* <small className="w-50 text-center py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-shopping-bag text-primary me-2"></i>Add to cart</Link>
                                    </small> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="product-item">
                                <div className="position-relative bg-light overflow-hidden">
                                    <img className="img-fluid w-100" src="assets/img/Picture10.jpg" alt="mdfa" />
                                    <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">2</div>
                                </div>
                                <div className="text-center p-4">
                                    <Link className="d-block h5 mb-2" to="#">Climate Change</Link>
                                    <span className="text-primary me-1">
                                        Sensitization of farmers on climate change mitigation measures in Buwunga Sub County
                                    </span>
                                    {/* <span className="text-body text-decoration-line-through">$29.00</span> */}
                                </div>
                                <div className="d-flex border-top">
                                    <small className="w-50 text-center border-end py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-eye text-primary me-2"></i>View detail</Link>
                                    </small>
                                    {/* <small className="w-50 text-center py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-shopping-bag text-primary me-2"></i>Add to cart</Link>
                                    </small> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="product-item">
                                <div className="position-relative bg-light overflow-hidden">
                                    <img className="img-fluid w-100" src="assets/img/Picture11.jpg" alt="mdfa" />
                                    <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">3</div>
                                </div>
                                <div className="text-center p-4">
                                    <Link className="d-block h5 mb-2" to="#">Farmers' Tree Training</Link>
                                    <span className="text-primary me-1">
                                        Farmers receiving trees for planting after the training
                                    </span>
                                    {/* <span className="text-body text-decoration-line-through">$29.00</span> */}
                                </div>
                                <div className="d-flex border-top">
                                    <small className="w-50 text-center border-end py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-eye text-primary me-2"></i>View detail</Link>
                                    </small>
                                    {/* <small className="w-50 text-center py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-shopping-bag text-primary me-2"></i>Add to cart</Link>
                                    </small> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="product-item">
                                <div className="position-relative bg-light overflow-hidden">
                                    <img className="img-fluid w-100" src="assets/img/Picture12.jpg" alt="mdfa" />
                                    <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">4</div>
                                </div>
                                <div className="text-center p-4">
                                    <Link className="d-block h5 mb-2" to="#">Planting Trees</Link>
                                    <span className="text-primary me-1">
                                        Left to right- Masaka City Mayor (Ms Florence Namayanja) and newly installed Mukiise M Mr Herman planting trees at Mukungwe Sub County 
                                    </span>
                                    {/* <span className="text-body text-decoration-line-through">$29.00</span> */}
                                </div>
                                <div className="d-flex border-top">
                                    <small className="w-50 text-center border-end py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-eye text-primary me-2"></i>View detail</Link>
                                    </small>
                                    {/* <small className="w-50 text-center py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-shopping-bag text-primary me-2"></i>Add to cart</Link>
                                    </small> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="product-item">
                                <div className="position-relative bg-light overflow-hidden">
                                    <img className="img-fluid w-100" src="assets/img/Picture13.jpg" alt="mdfa" />
                                    <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">5</div>
                                </div>
                                <div className="text-center p-4">
                                    <Link className="d-block h5 mb-2" to="#">Nursery Bed</Link>
                                    <span className="text-primary me-1">
                                        MDFA central nursery bed, our source for trees
                                    </span>
                                    {/* <span className="text-body text-decoration-line-through">$29.00</span> */}
                                </div>
                                <div className="d-flex border-top">
                                    <small className="w-50 text-center border-end py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-eye text-primary me-2"></i>View detail</Link>
                                    </small>
                                    {/* <small className="w-50 text-center py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-shopping-bag text-primary me-2"></i>Add to cart</Link>
                                    </small> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="product-item">
                                <div className="position-relative bg-light overflow-hidden">
                                    <img className="img-fluid w-100" src="assets/img/Picture14.jpg" alt="mdfa" />
                                    <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">6</div>
                                </div>
                                <div className="text-center p-4">
                                    <Link className="d-block h5 mb-2" to="#">Alliance High School </Link>
                                    <span className="text-primary me-1">
                                        Alliance High school students after receiving trees for planting
                                    </span>
                                    {/* <span className="text-body text-decoration-line-through">$29.00</span> */}
                                </div>
                                <div className="d-flex border-top">
                                    <small className="w-50 text-center border-end py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-eye text-primary me-2"></i>View detail</Link>
                                    </small>
                                    {/* <small className="w-50 text-center py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-shopping-bag text-primary me-2"></i>Add to cart</Link>
                                    </small> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="product-item">
                                <div className="position-relative bg-light overflow-hidden">
                                    <img className="img-fluid w-100" src="assets/img/Picture15.jpg" alt="mdfa" />
                                    <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">7</div>
                                </div>
                                <div className="text-center p-4">
                                    <Link className="d-block h5 mb-2" to="#">Energy Conservation</Link>
                                    <span className="text-primary me-1">
                                        Energy conservation through briquettes production
                                    </span>
                                    {/* <span className="text-body text-decoration-line-through">$29.00</span> */}
                                </div>
                                <div className="d-flex border-top">
                                    <small className="w-50 text-center border-end py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-eye text-primary me-2"></i>View detail</Link>
                                    </small>
                                    {/* <small className="w-50 text-center py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-shopping-bag text-primary me-2"></i>Add to cart</Link>
                                    </small> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="product-item">
                                <div className="position-relative bg-light overflow-hidden">
                                    <img className="img-fluid w-100" src="assets/img/Picture16.jpg" alt="mdfa" />
                                    <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">8</div>
                                </div>
                                <div className="text-center p-4">
                                    <Link className="d-block h5 mb-2" to="#">Energy Saving Stoves</Link>
                                    <span className="text-primary me-1">
                                        Some of the energy saving stoves produced by youth groups in Masaka District
                                    </span>
                                    {/* <span className="text-body text-decoration-line-through">$29.00</span> */}
                                </div>
                                <div className="d-flex border-top">
                                    <small className="w-50 text-center border-end py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-eye text-primary me-2"></i>View detail</Link>
                                    </small>
                                    {/* <small className="w-50 text-center py-2">
                                        <Link className="text-body" to="#"><i className="fa fa-shopping-bag text-primary me-2"></i>Add to cart</Link>
                                    </small> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-center">
                            <Link className="btn btn-primary rounded-pill py-3 px-5" to="#">Browse More Products</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
