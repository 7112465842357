import React from 'react';
import { Link } from 'react-router-dom';
import SampleBlog from '../components/SampleBlog';

export default function Blog() {
  return (
    <React.Fragment>
        {/* Page Header Start  */}
        <div className="container-fluid page-header wow fadeIn" data-wow-delay="0.1s">
            <div className="container">
                <h1 className="display-3 mb-3 animated slideInDown">Blog Grid</h1>
                <nav aria-label="breadcrumb animated slideInDown">
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item"><Link className="text-body" to="#">Home</Link></li>
                        <li className="breadcrumb-item"><Link className="text-body" to="#">Pages</Link></li>
                        <li className="breadcrumb-item text-dark active" aria-current="page">Blog Grid</li>
                    </ol>
                </nav>
            </div>
        </div>
        {/* Page Header End  */}


        {/* Blog Start  */}
        <div className="container-xxl py-6">
            <div className="container">
                <div className="section-header text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: '500px'}}>
                    <h1 className="display-5 mb-3">Latest Blog</h1>
                    <p>
                        Masaka District Farmers Association joins the rest of the 
                        world to celebrate the International Day of Forests 
                        (IDF) by planting trees in Busense Village, Kabonera 
                        Sub County in Masaka District on 22ndMarch 202I under 
                        the theme “Forest Restoration: A Path To Recovery and 
                        Well being”
                    </p>
                </div>
                
                <div className="row g-4">
                    
                    <SampleBlog />

                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <img className="img-fluid" src="assets/img/blog-7.jpg" alt="" />
                        <div className="bg-light p-4">
                            <Link className="d-block h5 lh-base mb-4" to="">
                                Board members present during the official launch of the Food processing factory
                            </Link>
                            <p>
                                Masaka District Farmers Association holds its Annual General 
                                Meeting (AGM) on 21st October 2021 during which the Guest of 
                                Honor Mrs.Teopista Ssenkungo Lule (the Resident District Commissioner 
                                for Masaka District) urges farmers to invest 
                            </p>
                            <div className="text-muted border-top pt-4">
                                <small className="me-3"><i className="fa fa-user text-primary me-2"></i>Admin</small>
                                <small className="me-3"><i className="fa fa-calendar text-primary me-2"></i>21 Oct, 2021</small>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                        <img className="img-fluid" src="assets/img/blog-8.jpg" alt="" />
                        <div className="bg-light p-4">
                            <Link className="d-block h5 lh-base mb-4" to="">
                                MasterCard representative- Ms.Asiimwe Doreen addressing 
                                participants during ICT for farmers training
                            </Link>
                            <p>
                                UNFFE in partnership with MasterCard Uganda extend digital 
                                skilling to Masaka farmers through ICT for farmer training 
                                to ease extension, information sharing and marketse.t.c for 
                                farmers. The training held at MDFA Offices in Kirumba on 
                                4th November 2021.
                            </p>
                            <div className="text-muted border-top pt-4">
                                <small className="me-3"><i className="fa fa-user text-primary me-2"></i>Admin</small>
                                <small className="me-3"><i className="fa fa-calendar text-primary me-2"></i>04 Nov, 2021</small>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                        <img className="img-fluid" src="assets/img/blog-9.jpg" alt="" />
                        <div className="bg-light p-4">
                            <Link className="d-block h5 lh-base mb-4" to="">
                                Men’s Forum Against Domestic Violence Uganda representative -
                                Ndawula during a sharing session with participants
                            </Link>
                            <p>
                                Vi Agroforestry in partnership with Men’s Forum Against Domestic 
                                Violence Uganda hold 3 days workshop on male action group 
                                discussion (ebimeezabyabasajja) at Masaka District Farmers 
                                Association Offices in Kirumba  as a way forward to bring men 
                                at the fore front in fighting Gender Based Violence (GBV)
                            </p>
                            <div className="text-muted border-top pt-4">
                                <small className="me-3"><i className="fa fa-user text-primary me-2"></i>Admin</small>
                                <small className="me-3"><i className="fa fa-calendar text-primary me-2"></i>01 Jan, 2021</small>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <img className="img-fluid" src="assets/img/blog-10.jpg" alt="" />
                        <div className="bg-light p-4">
                            <Link className="d-block h5 lh-base mb-4" to="">
                                St Joseph’s Primary school pupils participating in tree 
                                planting during International Day of Forests celebration 
                                in Gulama Village, Buwunga Sub County.
                            </Link>
                            <p>
                                International Day of forests in Gulama Buwunga Sub County.
                                Masaka DFA chooses Gulama Village in Buwunga Sub county 
                                Masaka District for celebration of International Day of 
                                Forests on 21st March 2022 under the theme “Forests and 
                                sustainable production and consumption”. During the event, 
                                different stakeholders from the district, Buganda Government, 
                                area local council, Vi agroforestry and schools like St 
                                Gerald Primary, Glad Park junior school, Nakasojjo SS attended.  
                                A total of 8200 trees were planted as away to restore cut trees 
                                through charcoal burning in the area.

                            </p>
                            <div className="text-muted border-top pt-4">
                                <small className="me-3"><i className="fa fa-user text-primary me-2"></i>Admin</small>
                                <small className="me-3"><i className="fa fa-calendar text-primary me-2"></i>21 March, 2022</small>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                        <img className="img-fluid" src="assets/img/blog-11.jpg" alt="" />
                        <div className="bg-light p-4">
                            <Link className="d-block h5 lh-base mb-4" to="">
                                Different stakeholders expressing ideas during the Coffee Multi stake holder Platform meeting
                            </Link>
                            <p>
                                Coffee multi stake holder platform meeting.
                                Coffee farmers in Masaka are happy about considering coffee 
                                in MDFA programs. This meeting has been realized through the Coffee 
                                multi stake holder platform meeting organized by Uganda 
                                National Farmers Federation(UNFFE) held at MDFA Offices. 
                                Thing meeting has been embraced by the LC5 Chairman Masaka –
                                Mr Battemyetoo Anderea Lukyamuzi, Masaka District Commercial 
                                Officer – Ms Nalukenge Barbra, UNFFE Director for policy and 
                                Advocacy-Mr Gumisiriza Caleb and coffee farmers in Masaka.
                            </p>
                            <div className="text-muted border-top pt-4">
                                <small className="me-3"><i className="fa fa-user text-primary me-2"></i>Admin</small>
                                <small className="me-3"><i className="fa fa-calendar text-primary me-2"></i>21 March, 2022</small>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                        <img className="img-fluid" src="assets/img/blog-12.jpg" alt="" />
                        <div className="bg-light p-4">
                            <Link className="d-block h5 lh-base mb-4" to="">
                                Left – Ms Birungi Flaviah addressing coffee farmers during the 
                                dialogue at MDFA Offices in Kirumba
                            </Link>
                            <p>
                                Dialogue on horticulture by Solidaridad
                                In support with Uganda National Farmers Federation (UNFFE) 
                                and Solidaridad, Masaka DFA organizes a dialogue between 
                                stakeholders in horticulture value chain mainly targeting 
                                vegetables in Masaka District on 5th April 2022. 
                                Vegetable farmers from the different subcounties of Buwunga, 
                                Kyanamukaka, Kabonera, Mukungwe and Bukakata, embrace the 
                                meeting as they shared ideas affecting the sector.
                            </p>
                            <div className="text-muted border-top pt-4">
                                <small className="me-3"><i className="fa fa-user text-primary me-2"></i>Admin</small>
                                <small className="me-3"><i className="fa fa-calendar text-primary me-2"></i>05 April, 2022</small>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                        <Link className="btn btn-primary rounded-pill py-3 px-5" to="">Load More</Link>
                    </div>
                </div>
            </div>
        </div>
        {/* Blog End  */}
    </React.Fragment>
  )
}
